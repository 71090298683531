<template>
  <div>
    <!-- 面包屑导航区 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>订单管理</el-breadcrumb-item>
      <el-breadcrumb-item>订单评价</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card>
      <el-row :gutter="20">
        <el-col :span="8">
          <el-input
            placeholder="请输入订单号进行搜索"
            v-model="queryinfo.query"
            clearable
            size="small"
            @clear="getGoodList()"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @mouseover.native="mouseOver()"
              @mouseout.native="mouseLeave()"
              @click="getGoodList()"
            ></el-button>
          </el-input>
        </el-col>
      </el-row>
      <el-row>
        <br />
        <!-- table 表格区域
        <el-button type="primary" size="mini" icon="fa fa-check" plain
          >&nbsp;批量置为无效</el-button
        >-->
      </el-row>
      <!-- table 表格区域 -->
      <el-table
        :data="goodsList"
        border
        stripe
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column type="index" label="#"></el-table-column>
        <!-- table 扩展区域 -->
        <el-table-column type="expand">
          <template slot-scope="props">
            <el-form label-position="left" inline class="demo-table-expand">
              <el-form-item label="创建时间">
                <span>{{ props.row.createTime }}</span>
              </el-form-item>
              <br />
              <el-form-item label="更新时间">
                <span>{{ props.row.updateTime }}</span>
              </el-form-item>
            </el-form>
          </template>
        </el-table-column>
        <el-table-column
          label="订单号"
          prop="orderNumber"
          width="180px"
        ></el-table-column>
        <el-table-column label="上架用户" prop="optName" width="110px">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.optName" size="medium" type="warning">{{ scope.row.optName }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="出发地" prop="placeIn" width="110px">
          <template slot-scope="scope">
            <el-tag size="medium">{{ scope.row.placeIn }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="目的地" prop="placeOut" width="110px">
          <template slot-scope="scope">
            <el-tag size="medium" type="warning">{{
              scope.row.placeOut
            }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="原出发日期" prop="departureTime" width="110px">
          <template slot-scope="scope">
            <el-tag size="medium">{{ scope.row.departureTime }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column
          label="新出发日期"
          prop="newDepartureTime"
          width="110px"
        >
          <template slot-scope="scope">
            <el-tag size="medium" type="warning">{{
              scope.row.newDepartureTime
            }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="原标签名称" prop="tagName" width="110px">
          <template slot-scope="scope">
            <el-tag size="medium">{{ scope.row.tagName }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="新标签名称" prop="newTagName" width="110px">
          <template slot-scope="scope">
            <el-tag size="medium" type="warning">{{
              scope.row.newTagName
            }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="处理状态" prop="changeStatus" width="110px">
          <template slot-scope="scope">
            <el-tag
              v-if="scope.row.changeStatus == '2'"
              size="medium"
              type="success"
              >系统处理中</el-tag
            >
            <el-tag
              v-if="scope.row.changeStatus == '1'"
              size="medium"
              type="success"
              >同意改签</el-tag
            >
            <el-tag
              v-if="scope.row.changeStatus == '3'"
              size="medium"
              type="error"
              >已撤销</el-tag
            >
            <el-tag
              v-if="scope.row.changeStatus == '0' && scope.row.refundSum"
              size="medium"
              type="success"
              >不同意改签</el-tag
            >
          </template>
        </el-table-column>
        <el-table-column width="70px" label="审核状态">
          <template slot-scope="scope">
            <i
              class="el-icon-success"
              v-if="scope.row.status"
              style="color: lightgreen; font-size: 20px;"
            ></i>
            <i
              class="el-icon-error"
              v-else
              style="color: red;font-size: 20px;"
            ></i>
          </template>
        </el-table-column>
        <el-table-column v-if="false" fixed="right" label="操作" width="180px">
          <el-button
            type="primary"
            size="mini"
            icon="fa fa-pencil"
            plain
            circle
            @click="showEditDialog(scope.row.goodsId)"
          ></el-button>
        </el-table-column>
        <el-table-column label="操作" width="130px">
          <template slot-scope="scope">
            <el-button
              type="primary"
              size="mini"
              icon="fa fa-pencil"
              plain
              circle
              @click="showEditDialog(scope.row)"
            ></el-button>
          </template>
        </el-table-column>
      </el-table>

      <!-- 页面分页区域 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryinfo.pagenum"
        :page-sizes="[5, 10, 20, 50]"
        :page-size="queryinfo.pagesize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        background
      >
      </el-pagination>

      <el-dialog
        title="安全校验"
        :visible.sync="dialogFormVisible"
        width="500px"
      >
        <el-form :model="valid">
          <el-form-item label="请输入登录密码" :label-width="'120px'">
            <el-input
              v-model="valid.pwd"
              type="password"
              autocomplete="off"
              style="width:240px"
            ></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取 消</el-button>
          <el-button type="success" @click="validPwd">校 验</el-button>
        </div>
      </el-dialog>
      <el-dialog
        title="退款审核"
        :visible.sync="dialogFormVisible1"
        width="500px"
      >
        <el-alert
          title="请在5分钟之内完成操作，否则需要重新进行安全校验"
          type="error"
          center
          :closable="false"
          style="margin-bottom: 10px;margin-top: 8px;"
        >
        </el-alert>
        <div style="margin-top:24px;">
          <el-radio v-model="radio1" label="1" border>同意改签</el-radio>
          <el-radio v-model="radio1" label="2" border>不同意改签</el-radio>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible1 = false">取 消</el-button>
          <el-button type="warning" @click="refund()">确定</el-button>
        </div>
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
import Qs from 'qs'
export default {
  name: 'orderChanged',
  data() {
    return {
      valid: {
        pwd: ''
      },
      dialogFormVisible: false,
      dialogFormVisible1: false,
      radio1: '1', // 查询参数对象
      queryinfo: {
        query: '',
        pagenum: 1,
        pagesize: 5
      },
      goodsList: [],
      total: 0,
      visible: false,
      lists: [],
      row: {},
      uuid: ''
    }
  },
  methods: {
    mouseOver() {
      this.active = 'background-color: #ccc;'
      //   console.log('鼠标移动事件')
    },
    mouseLeave() {
      this.active = ''
    },
    // 退款
    async refund() {
      const { data: res } = await this.$http.put(
        `/ticketChanges/${this.radio1}/${this.row.id}/${this.uuid}`
      )
      if (res.code !== '20000') {
        this.dialogFormVisible1 = false
        return this.$message.error(res.message)
      } else {
        this.dialogFormVisible1 = false
        return this.$message.success(res.message)
      }
    },
    async validPwd() {
      var username = window.sessionStorage.getItem('username')
      const pwd = this.valid.pwd
      const { data: res } = await this.$http.put(
        `/admins/validPwd/${username}/${pwd}`
      )
      if (res.code !== '20000') {
        this.$message.error(res.message)
        return false
      }
      this.dialogFormVisible = false
      this.$message.success(res.message)
      this.uuid = res.data.valid
      this.dialogFormVisible1 = true
      this.valid.pwd = ''
    },
    showEditDialog(row) {
      this.dialogFormVisible = true
      this.row = row
    },
    // 根据分页获取商品列表的全部数据
    async getGoodList() {
      const { data: res } = await this.$http.get('ticketChanges', {
        params: this.queryinfo
      })
      if (res.code !== '20000') {
        this.$message.error(res.message)
        return
      }
      this.$message.success('获取评价列表成功！')
      console.log(res.data)
      this.goodsList = res.data.lists
      this.total = Number(res.data.total)
    },
    handleSizeChange(newSize) {
      this.queryinfo.pagesize = newSize
      this.getGoodList()
    },
    handleCurrentChange(newPage) {
      this.queryinfo.pagenum = newPage
      this.getGoodList()
    },
    handleSelectionChange(val) {
      // var listTemp = []
      // val.forEach(item => {
      //   listTemp.push(Number(item.goodsId))
      // })
      // this.goodsArr = listTemp
    },
    addCoupon() {}
  },
  mounted() {},
  created() {
    this.getGoodList()
  }
}
</script>

<style lang="less" scoped></style>
